import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

function Statistics() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Set to false to trigger every time the section is in view
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
    <section id="statistics" ref={ref}>
      <div className="block color-scheme-2">
        <div className="container">
          <div className="section-sub-title center">
            <article className="section-title-body white">
              <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">From Zero to Viral: Our Triumphs</h1>
              <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
              <p className="head-text wow fadeInUp animated text-center mt-4 text-lg leading-relaxed text-gray-700 uppercase" data-wow-duration="1.5s">
                At our core, we’re all about measurable growth. Through detailed analytics, 
                metrics, and performance tracking, we translate every campaign’s impact into tangible
                results. From audience reach to conversion rates, our data-driven approach allows us to 
                optimize strategies in real time, ensuring that each influencer collaboration hits its 
                mark. By spotlighting key insights, we give you a transparent view of how we transform big 
                ideas into measurable wins, time after time.
              </p>
            </article>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="stat-block bg-gray-200 text-gold-500 hover:scale-105 transition-transform duration-300">
                <h3 className="stat-title">INCREASED REVENUE</h3>
                <p className="stat-value text-4xl">
                  {inView && <CountUp end={40} duration={2.5} separator="," />}%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="stat-block bg-gray-200 text-gold-500 hover:scale-105 transition-transform duration-300">
                <h3 className="stat-title">AVG GROWTH RATE</h3>
                <p className="stat-value text-4xl">
                  {inView && <CountUp end={65} duration={2.5} separator="," />}%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="stat-block bg-gray-200 text-gold-500 hover:scale-105 transition-transform duration-300">
                <h3 className="stat-title">VIEWS</h3>
                <p className="stat-value text-4xl">
                  {inView && <CountUp end={100000} duration={2.5} separator="," />}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="stat-block bg-gray-200 text-gold-500 hover:scale-105 transition-transform duration-300">
                <h3 className="stat-title">CPM</h3>
                <p className="stat-value text-4xl">
                  ${inView && <CountUp end={20} duration={2.5} separator="," />}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .stat-block {
          background: #202020; /* Dark gray background */
          padding: 20px;
          margin: 15px;
          border-radius: 15px;
          box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
          text-align: center;
          transition: transform 0.2s ease-in-out;
        }
        .stat-block:hover {
          transform: scale(1.10);
        }
        .stat-title {
          color: #ffffff; /* Gold text */
          font-size: 1.25rem;
          margin-bottom: 30px;
        }
        .stat-value {
          color: #b46e3c; /* Gold text */
          font-size: 2.5rem; /* Adjust size as needed */
        }
        .head-text {
          font-size: 15px;
          line-height: 1.5;
          color: #333;
          margin-top: 20px;
          text-align: center;
          margin: 0 auto;
          max-width: 800px; /* Adjust the max-width as needed */
          text-transform: uppercase;
        }
      `}</style>
    </section>
  );
}

export default Statistics;