import React from 'react'

function Welcome2() {
    return (
        <section id="welcome">
            <div className="block color-scheme-1">
                
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9">
                            <div className="section-sub-title center">
                                <article className="section-title-body white">
                                    <h1 className="head-title">
                                        <span>FullScope</span> is a global <span>full-stack service</span> specializing in video editing (both full-length and shorts), thumbnail design, ideation, and data analysis. Our mission is to help content creators and influencers make data-driven decisions to ensure growth across their social media platforms, optimizing performance and maximizing audience engagement.</h1>
                                    <p className="head-text">
                                    Driven by our passion for content creation and commitment to measurable results, we offer comprehensive solutions that go beyond traditional services. Our approach empowers influencers and creators to make data-driven decisions, optimize their content, and achieve rapid growth across social media platforms, ensuring they excel in today’s competitive digital landscape.
                                    </p>
                                </article>
                            </div>   
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Welcome2
