import React from 'react';
import Header1 from './../components/Header/Header1';
import Welcome2 from './../components/Welcome/Welcome2';
import About1 from './../components/About/About1';
import Service3 from './../components/Service/Service3';
import Portfolio3 from './../components/Portfolio/Portfolio3';
import Contact2 from './../components/Contact/Contact2';
// import Newsletter1 from './../components/Newsletter/Newsletter2';
// import Map from './../components/Map';
import Footer3 from './../components/Footer/Footer3';
import SimpleSlider1 from './../components/Sliders/Slick-slider1';
// import Client from './../components/Clients';
import Team from './../components/Team';
import Statistics1 from './../components/Statistics/Statistics1';
import NewsletterForm from './../components/Newsletter/NewsletterForm';

function Home1() {
  return (
    <>
      
      <div  id="home" className="full-screen-block">
      <Header1/>
      <SimpleSlider1/>
      </div>
      <Welcome2/>
      <About1/>
      <Statistics1/>
      <Service3/>
      <Portfolio3/>
      {/* <Client/> */}
      <Team/>
      <Contact2/>
      {/* <Newsletter1/> */}
      {/* <Map/> */}
      <Footer3/>
    </>
  );
}

export default Home1;