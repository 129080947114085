import React from 'react';
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon } from "react-share";

function Footer3() {
  return (
    <>
      <footer id="footer">
        <div className="container">
          {/* Existing footer content */}
          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                <ul className="footer-icon list-unstyled">
                  <li>
                    <FacebookShareButton
                      url="https://www.facebook.com/profile.php?id=61552966482187"
                      quote="Anema"
                      hashtag="#anema"
                    >
                      <FacebookIcon round={true} size={32} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton url="https://www.linkedin.com/company/fullscopesmma/">
                      <LinkedinIcon round={true} size={32} />
                    </LinkedinShareButton>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-xs-12 col-sm-4">
                <div className="footer-logo">
                  <img src="img/FullScope2.png" alt="" />
                </div>
              </div>
            </div>

            {/* New row with centered links */}
            <div className="row">
  <div className="col-12 text-center">
    <ul className="list-inline">
      <li className="list-inline-item">
        <a href="/privacy.txt">Privacy Policy</a>
      </li>
      <li className="list-inline-item" style={{ color: 'white' }}>|</li>
      <li className="list-inline-item">
        <a href="/terms.txt">Terms and Conditions</a>
      </li>
      <li className="list-inline-item" style={{ color: 'white' }}>|</li>
      <li className="list-inline-item">
        <a href="/sitemap.xml">Site Map</a>
      </li>
    </ul>
<div className="row">
  <div className="col-12 text-center">
    <p style={{ color: 'white' }}>&copy; 2025 FullScope. All rights reserved.</p>
  </div>
</div>
  </div>
</div>
</div>
</div>
</footer>
</>
  );
}

export default Footer3;