import React from 'react'
import bg_vide from '../../videos/socialmedia4.mp4'
function About1() {
    
    return (
        <>
            <section id="about" className="bg-non-fixed">
            <video autoPlay loop muted className="bg_video">
                <source src={bg_vide} type="video/mp4"></source>
            </video>
            <div className="bg-inner-dark"></div>
            <div className="bg-50-r"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About</h1>
                                        <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                        <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                        At FullScope, we are more than just a Social Media Marketing Agency; we are your partners in navigating the dynamic and ever-expanding digital landscape. With a full-stack approach to digital marketing, we cater to businesses striving to carve their niche in the competitive online sphere.
                                        </p>
                                    </article>
                                </div>
                            </div> 

                        </div> 
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="pad-l-45 pad-r-45 block">

                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Our Unique Edge</h4>
                                <p>
                                Our Unique Edge What sets us apart is our extensive experience in both content creation and digital strategy. Our team has helped content creators amass over 50 million followers and 20 billion views across various social media platforms. With a deep understanding of the ever-changing digital landscape, we combine influencer insights with data-driven strategies to deliver impactful solutions that drive growth and engagement for creators. This unique blend of expertise allows us to stay ahead of trends and ensure success for our clients.  
                                </p>
                            </div>
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title">Decades of Digital Prowess</h4>
                                <p>
                                With over 15 years of experience in the world of influencer marketing and content creation, our team has developed a deep understanding of the digital landscape. We’ve evolved alongside the rise of social media, accumulating insights and expertise that enable us to craft tailored, data-driven solutions for each client. Our ability to stay ahead of trends and adapt to the shifting digital environment ensures that our strategies drive real growth and success for content creators.
                                </p>
                            </div>
                            
                            
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                            <div className="clearfix"></div>
                                
                                {/* <div className="progress">
                                    <div className="progress-bar" style={{width: "70%"}} >

                                    </div>
                                    <span className="skill-value">Graphic Design <span> 70%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "95%"}}>

                                    </div>
                                    <span className="skill-value">Web Design <span> 95%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "90%"}}>

                                    </div>
                                    <span className="skill-value">Web Developing <span> 90%</span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "85%"}}>

                                    </div>
                                    <span className="skill-value">Java Scripting <span> 85%</span></span>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About1
