import React, {useState} from 'react'
import {Link} from 'react-scroll'
import logo from './../../images/fullscope.png'

function Navbar1() {
    const [navToggle, setnavToggle] = useState(true);

    return (
        <>
            <nav className="navbar navbar-default  navbar-expand-md">
                            <div className="navbar-header">
                                <Link to='home'  className="header-logo"  smooth={false} duration={800}>
                                    <span></span>
                                    <img src={logo} alt="logo" className='logo'/>
                                </Link>
                                <button onClick={()=>setnavToggle(!navToggle)} type="button" className="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#bs-example-navbar-collapse-1" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                
                            </div>
                <div className={navToggle?'collapse navbar-collapse':'collapse navbar-collapse show'}  id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav  navbar-right menu-item-7" id="navbar-full">
                        <li className="current home"><span className="bg-icon"></span><span className="rotated-title">Home</span><Link to='home' className="link-item " smooth={false} duration={800} activeClass="active">Home</Link></li>
                        <li className="about"><span className="bg-icon"></span><span className="rotated-title">About</span><Link to='welcome' className="link-item" smooth={false} duration={800} activeClass="active">About</Link></li>
                        <li className="partners"><span className="bg-icon"></span><span className="rotated-title">Statistics</span><Link to='statistics' className="link-item" smooth={false} duration={800} activeClass="active">Statistics</Link></li>                                            
                        <li className="service"><span className="bg-icon"></span><span className="rotated-title">Services</span><Link to='services' className="link-item" smooth={false} duration={800} activeClass="active">Service</Link></li>
                        <li className="work"><span className="bg-icon"></span><span className="rotated-title">Portfolio</span><Link to='portfolio' className="link-item" smooth={false} duration={800} activeClass="active">Portfolio</Link></li>
                        {/* <li className="partners"><span className="bg-icon"></span><span className="rotated-title">Partners</span><Link to='partners' className="link-item" smooth={false} duration={800} activeClass="active">Partners</Link></li> */}
                        <li className="team"><span className="bg-icon"></span><span className="rotated-title">Team</span><Link to='team' className="link-item" smooth={false} duration={800} activeClass="active">Team</Link></li>
                        <li className="contact"><span className="bg-icon"></span><span className="rotated-title">Contact</span><Link to='contact' className="link-item" smooth={false} duration={800} activeClass="active">Contact</Link></li>
                    </ul>
                </div>
            </nav> 
        </>
    )
}

export default Navbar1
