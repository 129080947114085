import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-image',
        title:'Thumbnail Design',
        text:'Capture attention and drive clicks with our custom thumbnail design services. We create visually compelling thumbnails that are not only eye-catching but strategically designed to maximize your Click-Through Rate (CTR). By increasing CTR, You will boost viewership and overall engagement on your videos. Our thumbnails are tailored to your content style and optimized to stand out, ensuring your videos perform at their best across platforms. Let us help you make a powerful first impression and enhance your video success.'
    },
    {
        _id:'002',
        classIcon:'ion-stats-bars',
        title:'Data Analysis',
        text:'Leverage the power of insights with our data analysis services. We dive deep into your social media analytics to make data-driven-decisions that will help you grow your views and followers. By studying the patterns in audience behavior, content performance, and engagement trends, we optimize your strategy to ensure consistent growth. Our data-backed approach allows us to fine-tune your content, helping you reach your full potential and achieve long-term success across platforms.'
    },
    {
        _id:'003',
        classIcon:'ion-search',
        title:'Competitive Research',
        text:'Stay ahead of the competition with our comprehensive competitive research services. We analyze your miche, studying top-performing creators and trends to uncover what works best. By understanding your competitors strategies, strengths, and weaknesses, we provide insights that help you differentiate your content, tap into new opportunities, and maximize your growth. Our goal is to equip you with the knowledge needed to outperform the competition, increase your visibility, and dominate your space on social media.'
    },
    {
        _id:'004',
        classIcon:'ion-social-youtube-outline',
        title:'Channel Management',
        text:'Ensure your social media channels are fully optimized for success with our channel management services. We take care of everything from optimizing your channels layout and SEO to managing your uploads, captions, and scheduling. Our focus is on maximizing discoverability, improving engagement, and ensuring that each piece of content is strategically positioned for growth. Let us handle the day-to-day management so your channel runs smoothly, allowing you to focus on content creation while we optimize your platform for maximum impact.'
    },
    {
        _id:'005',
        classIcon:'ion-ios-film-outline',
        title:'Video Editing',
        text:'Unlock the full potential of your content with our professional video editing services. Whether it is long-form or short-form content, we edit in any style that matches your vision while maximizing viewer retention and boosting your views and watch time. From YouTube videos to Instagram Reels, TikToks, and more, we ensure your videos are polished, captivating, and optimized for maximum engagement and platform success. Let us handle the technical side, so you can focus on creating and growing your audience.'
    },
    {
        _id:'006',
        classIcon:'ion-ios-chatbubble-outline',
        title:'24/7 Hour Support',
        text:'Experience peace of mind with our 24/7 support services, because we are here for you around the clock, whenever you need us. Our dedicated team is always just a call or message away, ready to assist you with any questions, issues, or concerns. With our unwavering support, you can focus on what matters most, knowing we have got your back, day and night.'
    }
]
function Service3() {
    return (
        <>
        <section  id="services">
        <div className="bg-dark"></div> 
            <div className="section-block-title">
                <div className="section-title">   
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-sm-10">
                                <article className="section-title-body">
                                    <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Services</h1>
                                    <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                    <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                    Our services cover everything from video editing to data analysis, ensuring that content creators can focus soley on recording while we handle the rest. Whether it is developing content strategies, designing thumbnails, managing uploads, or analyzing performance metrics, we provide full-stack solutions that drive growth and optimize results across all social media platforms. We take care of every aspect, so creatos can thrive without the hassle of managing the backend.
                                    </p>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
                <div className="block color-scheme-blur">
                    <div className="container">
                        <div className="row">
                            {services.map((service)=>(
                            <div className="col-lg-4 col-md-6"  key={service._id}>
                                <div className="block-feature">
                                    <i className={service.classIcon}></i>
                                    <a href="/">{service.title}</a>
                                    <p>
                                    {service.text}
                                    </p>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
            
        </section>
        </>
    )
}

export default Service3
