import React from 'react'
// import {FacebookShareButton,
//     PinterestShareButton,
//     TelegramShareButton,
//     LinkedinShareButton,
//     TwitterShareButton} from "react-share";
// import {FacebookIcon,
//     PinterestIcon,
//     TelegramIcon,
//     LinkedinIcon,
//     TwitterIcon} from "react-share";
function Team() {
  
    return (
        <>
        <section id="team">
            <div className="block color-scheme-2">
                <div className="container">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">Meet Our Team</h1>
                        </article>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid team-image" src="img/preview/Rob.jpeg" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Founder & CEO</p>
                                        <h4>Robert Murphy</h4>
                                    </div>

                                </figure>
                            </article> 
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid team-image" src="img/preview/Brian2.png" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Co-Founder & COO</p>
                                        <h4>Brian Murphy</h4>
                                    </div>

                                </figure>
                            </article> 
                        </div>
                        
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <article className="author-block">
                                <figure className="figure-hover-overlay">
                                    <img className="img-fluid team-image" src="img/preview/Jennifer_Arnold_School2.png" alt="" title=""/>

                                    <div className="author-information">
                                        <p className="author-prof">Developer & CIO</p>
                                        <h4>Jennifer Arnold</h4>
                                    </div>

                                </figure>
                            </article> 
                        </div>
                    </div>
                </div>
            </div>          
        </section>
        <style jsx>{`
          .team-image {
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 15px;
          }
        `}</style>
        </>
    )
}

export default Team
